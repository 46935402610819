<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid style="max-width: 1600px">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search
              <span style="padding-left: 2px">({{ totalRecord }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.loadNumber"
                    label="Load Number"
                    type="text"
                    @keydown.enter="searchAllPayableLoads()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.customerRefId"
                    label="Customer Ref. Id"
                    type="text"
                    @keydown.enter="searchAllPayableLoads()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <carrier-auto-complete
                    carrier-text="Carrier"
                    class="pt-3"
                    @event="selectedCarrier"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.invoiceNumber"
                    label="Invoice Number"
                    type="text"
                    @keydown.enter="searchAllPayableLoads()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn
                    class="blue white--text dark-3"
                    small
                    @click="searchAllPayableLoads()"
                  >
                    <v-icon small> mdi-search-web </v-icon>
                    Filter
                  </v-btn>
                  <v-btn class="ml-1" small @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>
    <v-col cols="12">
      <v-container class="pt-0 data-container" fluid>
        <v-data-table
          :headers="headers"
          :items="payablesLoads"
          :items-per-page="pageSize"
          :loading="loadingIcon"
          calculate-widths
          class="elevation-2 data-table"
          fixed-header
          hide-default-footer
        >
          <template v-slot:item.loadNumber="{ item }">
            <v-btn
              :to="{
                name: 'Load Detail',
                params: { id: item.loadNumber },
              }"
              small
              target="_blank"
              text
            >
              {{ item.loadNumber }}
            </v-btn>
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                name: 'Customer Detail',
                params: { id: item.customer.id },
              }"
              class="name-link"
              target="_blank"
            >
              {{ item.customer.name }}
            </router-link>
          </template>
          <template v-slot:item.carrierName="{ item }">
            <router-link
              :to="{
                name: 'Carrier Detail',
                params: {
                  id: item.carrier.id,
                  title: item.carrier.name + ' - ' + item.carrier.mc_number,
                },
              }"
              class="name-link"
              target="_blank"
            >
              <span style="color: #464646">{{ item.carrierName }}</span>
            </router-link>
          </template>
          <template v-slot:item.paymentMethod="{ item }">
            <span v-if="item.carrier.primary_payment_method">
              <p class="mb-0"><b>{{ item.carrier.primary_payment_method.payment_method }}</b></p>
              <p class="mb-0">{{ item.carrier.primary_payment_method.name }}</p>
              <template v-if="item.carrier.primary_payment_method.payment_method === 'ACH'">
                <p class="mb-0">Account Number: {{ item.carrier.primary_payment_method.account_number }}</p>
                <p class="mb-0">Routing Number: {{ item.carrier.primary_payment_method.routing_number }}</p>
              </template>
              <template v-else>
                <p class="mb-0">{{ item.carrier.primary_payment_method.businessName }}</p>
                <p class="mb-0">{{ item.carrier.primary_payment_method.address_line_1 }}</p>
                <p class="mb-0">{{ formatLocation(item.carrier.primary_payment_method.city, item.carrier.primary_payment_method.state) }} {{ item.carrier.primary_payment_method.postal_code }}</p>
              </template>
            </span>
            <span v-else>
              <p class="mb-0">Not configured</p>
            </span>
          </template>
          <template v-slot:item.paymentType="{ item }">
            {{ item.carrier.quick_pay ? "Quick Pay" : "Net 30" }}
          </template>
          <template v-slot:item.email="{ item }">
            <template v-if="item.carrier.primary_payment_method && item.carrier.primary_payment_method.payment_method === 'FACTORING'">
              <p v-if="item.carrier.primary_payment_method.email" class="mb-0">
                <v-icon small> mdi-email </v-icon>
                <span>{{ item.carrier.primary_payment_method.email }}</span>
              </p>
              <p v-if="item.carrier.primary_payment_method.email" class="mb-0">
                <v-icon small> mdi-phone </v-icon>
                <span>{{ item.carrier.primary_payment_method.phone }}</span>
              </p>
            </template>
            <template v-else>
              <p v-if="item.email" class="mb-0">
                <v-icon small> mdi-email </v-icon>
                <span>{{ item.email }}</span>
              </p>
              <p v-if="item.email" class="mb-0">
                <v-icon small> mdi-phone </v-icon>
                <span>{{ item.phone }}</span>
              </p>
            </template>
          </template>
          <template v-slot:item.loadMargin="{ item }">
            <p class="mb-0">
              <b>Carrier Price:</b>
              <span v-if="item.carrierPrice" class="blue--text text--darken">
                ${{ item.carrierPrice }}</span
              >
            </p>
            <p class="mb-0">
              <b>Accessorial:</b>
              <span v-if="item.loadMargin" class="blue--text text--darken">
                ${{
                  item.loadMargin.carrier_total_accessorials_paid_to_carrier
                }}
              </span>
            </p>
            <p class="mb-0">
              <b>Total:</b>
              <span v-if="item.loadMargin" class="blue--text text--darken">
                ${{ item.loadMargin.carrier_total_billable_price }}
              </span>
            </p>
          </template>
          <template v-slot:item.invoicedDate="{ item }">
            <span>{{ item.invoicedDate || "N/A" }}</span>
          </template>
          <template v-slot:item.uploadFile="{ item }">
            <a
              v-if="item.uploadFile"
              :href="item.uploadFile"
              class="name-link"
              target="_blank"
              ><v-icon small>mdi-file-pdf-outline</v-icon>
            </a>
            <div v-else>No invoice file</div>
          </template>
          <template v-slot:item.pay="{ item }">
            <v-tooltip bottom :disabled="item.carrier.has_primary_payment_method || item.carrier.factoring_company">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="font-weight-bold blue darken-1 ml-2"
                  small
                  :disabled="!item.carrier.has_primary_payment_method && !item.carrier.factoring_company"
                  @click="
                    confirmChangeLoadStatus(
                      item.loadNumber,
                      item.loadMargin.carrier_total_billable_price,
                      item.carrier
                    )
                  "
                >
                  Pay
                </v-btn>
              </template>
              <span>Carrier has no configured payment method</span>
            </v-tooltip>
          </template>
          <template v-slot:footer>
            <v-page
              :page-size-menu="[10, 20, 50, 100]"
              :total-row="totalRecord"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-col>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Submit payment
        </v-card-title>
        <v-card-text>
          <p>Please confirm payment amount:</p>
          <p class="text-center mb-0">
            <b>${{ payAmount }}</b>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="orange darken-1" text @click="loadAmountPaid()">
            Pay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <carrier-a-r-edit @event="operationEvent" />
  </v-row>
</template>
<script>
import { mapGetters } from "vuex"
import CarrierAutoComplete from "@/views/pages/components/CarrierAutoComplete"
import CarrierAREdit from "@/views/pages/account/CarrierAREdit"
import common from "@/utils/common"


export default {
  name: "Payables",
  components: {
    CarrierAutoComplete,
    CarrierAREdit,
  },
  data() {
    return {
      checkType: this.digital_check,
      dialog: false,
      loadId: null,
      payAmount: null,
      payCarrier: null,
      searchParam: {
        loadNumber: null,
        customerRefId: null,
        carrierId: null,
        invoiceNumber: null,
      },
      headers: [
        {
          text: "Customer",
          align: "center",
          sortable: false,
          value: "customer",
          width: "10%",
        },
        {
          text: "Load #",
          value: "loadNumber",
          align: "center",
          sortable: false,
          width: "5%",
        },
        {
          text: "Carrier",
          value: "carrierName",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "Payment Method",
          value: "paymentMethod",
          align: "center",
          sortable: false,
        },
        {
          text: "Payment Type",
          value: "paymentType",
          align: "center",
          sortable: false,
        },
        {
          text: "Contact",
          value: "email",
          align: "center",
          sortable: false,
        },
        {
          text: "Invoice #",
          value: "carrierInvoiceNumber",
          align: "center",
          sortable: false,
        },
        {
          text: "Invoiced Date",
          value: "invoicedDate",
          align: "center",
          sortable: false,
        },
        {
          text: "Carrier Amount",
          value: "loadMargin",
          align: "right",
          sortable: false,
          width: "10%",
        },
        {
          text: "Invoice",
          value: "uploadFile",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "pay",
          align: "center",
          sortable: false,
          width: "2%",
        },
      ],
      payablesLoads: [],
      queryParams: {},
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
    }
  },
  computed: {
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
    }),
    ...mapGetters("billing", {
      payableLoadList: "payableLoadList",
    }),
  },
  watch: {
    payableLoadList() {
      this.totalRecord = this.payableLoadList.count
      this.payablesLoads = this.payableLoadList.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.receivableLoads = []
    this.getPayableLoads()
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    getPayableLoads() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.queryParams.ordering = "invoiced_date"
      this.$store.dispatch("billing/GET_PAYABLES_LOADS", this.queryParams)
    },
    resetSearch() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.searchParam.loadNumber = null
      this.searchParam.customerRefId = null
      this.searchParam.carrierId = null
      this.searchParam.invoiceNumber = null
      this.queryParams.ordering = "invoiced_date"
      this.$store.commit("generic/setCarrierId", null)
      this.$store.dispatch("billing/GET_PAYABLES_LOADS", this.queryParams)
    },
    searchAllPayableLoads() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.queryParams.ordering = "invoiced_date"
      if (this.searchParam.loadNumber) {
        this.queryParams.id = this.searchParam.loadNumber
      }
      if (this.searchParam.customerRefId) {
        this.queryParams.customer_reference_id__icontains =
          this.searchParam.customerRefId
      }
      if (this.searchParam.carrierId) {
        this.queryParams.carrier = this.searchParam.carrierId
      }
      if (this.searchParam.invoiceNumber) {
        this.queryParams.carrier_invoice_number__icontains =
          this.searchParam.invoiceNumber
      }
      this.$store.dispatch("billing/GET_PAYABLES_LOADS", this.queryParams)
    },
    readDataFromAPI(value) {
      if (this.payablesLoads.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch("billing/GET_PAYABLES_LOADS", this.queryParams)
      }
    },
    selectedCarrier(value) {
      if (value.carrier) {
        if (value.flag === 1) {
          this.searchParam.carrierId = value.carrier.id
          this.searchAllPayableLoads()
        } else {
          this.searchParam.carrierId = value.carrier.id
        }
      }
    },
    confirmChangeLoadStatus(loadId, carrierPay, carrier) {
      this.loadId = loadId
      this.payAmount = carrierPay
      this.dialog = true
      this.payCarrier = carrier
    },
    validateCarrierDetail() {
      const errorMessage = []
      if (this.payAmount <= 0) {
        errorMessage.push("Payment amount is less then equal to 0")
      }
      if (this.payCarrier.ar_name === null || this.payCarrier.ar_name === "") {
        errorMessage.push("Account Payable name must not be blank")
      }
      if (
        this.payCarrier.ar_address === null ||
        this.payCarrier.ar_address === ""
      ) {
        errorMessage.push("Account Payable address must not be blank")
      }
      if (this.payCarrier.ar_city === null || this.payCarrier.ar_city === "") {
        errorMessage.push("Account Payable city must not be blank")
      }
      if (
        this.payCarrier.ar_state === null ||
        this.payCarrier.ar_state === ""
      ) {
        errorMessage.push("Account Payable state must not be blank")
      }
      if (
        this.payCarrier.ar_zipcode === null ||
        this.payCarrier.ar_zipcode === ""
      ) {
        errorMessage.push("Account Payable zipcode must not be blank")
      }
      if (
        this.payCarrier.ar_email === null ||
        this.payCarrier.ar_email === ""
      ) {
        errorMessage.push("Account Payable email must not be blank")
      }
      return errorMessage
    },
    loadAmountPaid() {
      const errorMessage = this.validateCarrierDetail()
      if (errorMessage.length === 0) {
        const payload = {
          load_id: this.loadId,
          carrier_id: this.payCarrier.id,
        }
        this.$store
          .dispatch("billing/POST_CARRIER_PAYMENT", payload)
          .then(() => {
            this.$store.dispatch("billing/GET_PAYABLES_LOADS", this.queryParams)
            const snackbar = {
              status: true,
              text: "Load paid successfully.",
              type: common.data.snackbarType.success,
            }
            this.$store.commit("main/SET_SNACKBAR", snackbar)
          })
          .catch((e) => {})
      } else {
        const snackbar = {
          status: true,
          text: errorMessage,
          type: common.data.snackbarType.error,
        }
        this.$store.commit("main/SET_SNACKBAR", snackbar)
      }
      this.dialog = false
    },
    operationEvent() {
      this.$store.dispatch("billing/GET_PAYABLES_LOADS", this.queryParams)
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
